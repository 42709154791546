.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.about-flex {
  display: flex;
}

.title-flex {
  align-self: flex-start;
}

.card {
  width: 100%;
  margin: 8px;
  padding: 15px;
  border-radius: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.4s;
}

.card:hover {
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2);
}

.date,
.description {
  font-size: 0.85em;
}

.job {
  font-size: 0.9em;
  color: black;
}

.name {
  font-size: 1.1em;
  font-weight: 900;
}
